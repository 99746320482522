<template>
	<div>
		<Card>
			<template #content>
				<div class="fluid formgrid grid">
					<div class="field col-12 md-12">
						<h5>Movimientos:</h5>
					</div>
					<div class="field col-1">
                        <strong>Tasa $</strong>   
					</div>
					<div class="field col-3">
                        <InputNumber v-model="$store.state.tasa" mode="currency" currency="VEF" locale="en-US"/>
					</div>
					<div class="field col-4">
					</div>
					<div class="col-4">
						<Button label="Agregar Pago" icon="bi bi-credit-card-2-front" class="p-button-primary mr-4" @click="Dialog = true"/>
					</div>
					<div class="field col-12 md-12">
						<DataTable :value="$store.state.pago.datos" class="datatable-gridlines datatable-striped datatable-sm datatable-responsive datatable-customers" dataKey="id" >
							<Column field="fechad" header="Fecha">
								<template #body="slotProps">
									{{Fecha(slotProps.data.fecha)}}
								</template>
							</Column>
							<Column  header="Forma de Pago">
								<template #body="slotProps">
										{{slotProps.data.tipo.nombre}}
									<img style="width: 100px;" :src="url+'upload/'+slotProps.data.img" v-if="slotProps.data.img && slotProps.data.ext=='jpg'"/>
									<img style="width: 70px;" src="images/pdf.png" v-if="slotProps.data.img && slotProps.data.ext=='pdf'"/>
									<br>{{slotProps.data.referencia}}
								</template>
							</Column>
							<Column header="Monto $">
								<template #body="slotProps">
									${{Moneda(slotProps.data.monto)}}
								</template>
							</Column>
							<Column header="Monto Bs">
								<template #body="slotProps">
									Bs {{Moneda(slotProps.data.montobs)}}
								</template>
							</Column>
							<Column header="Eliminar">
								<template #body="slotProps">
									<Button icon="pi pi-trash" class="p-button-rounded p-button-danger mr-2" @click="Eliminar(slotProps.data)"/>
								</template>
							</Column>
						</DataTable>
					</div>
				</div>
			</template>
			<template #footer>						
				<div class="fluid formgrid grid" >
					<div class="col-6">
						<h5 v-if="moneda==1">Recibido: <strong>VEF {{Moneda($store.state.pago.montobs)}}</strong></h5>
						<h5 v-else>Recibido: <strong>$ {{Moneda($store.state.pago.monto)}} (BS.{{Moneda($store.state.pago.montobs)}})</strong></h5>
					</div>
					<div class="col-6" v-if="moneda==1">
						<h5>Pendiente: <strong>VEF {{Moneda($store.state.pago.deudabs - $store.state.pago.montobs)}}</strong></h5>
					</div>
					<div class="col-6" v-else>
						<h5>Pendiente: <strong>$ {{Moneda($store.state.pago.deuda - $store.state.pago.monto)}}</strong></h5>
					</div>
				</div>
			</template>
		</Card>	

		<Dialog v-model:visible="Dialog" :style="{width: size}" header="Agregar Pago" :modal="true" class="fluid" :maximizable="true">
            <br>
			<div class="fluid">
				<div class="grid">
					<div class="col-6">
                        <label>Tipo</label>
                        <div v-for="(stats, index) in tipopago" :key="index">
                            <RadioButton id="tipo" name="tipo" :value="stats" v-model="pago.tipo"/>
                            <strong> {{stats.nombre}}<br><br></strong>
                        </div>
					</div>
                    <div class="col-6" v-if="pago.tipo">
						<div class="grid fluid">
							<div class="col-7">
								<label for="name">Fecha</label>
								<InputText v-model="pago.fecha" type="date" :max="$store.state.fecha"/>
								<small class="p-invalid" v-if="!pago.fecha">Es requirido</small>
							</div>
                            <div class="col-8" v-if="pago.tipo.digital">
                                <label>Referencia</label>
                                <InputText v-model="pago.referencia" type="text" placeholder="Número"/>
								<small class="p-invalid" v-if="!pago.referencia">Es requirido</small>
                            </div>
							<div class="col-6" v-if="pago.tipo.moneda==2">
                                <label for="price">Monto $</label>
                                <InputNumber v-model="pago.monto" mode="currency" currency="USD" locale="en-US"/>
								<small class="p-invalid" v-if="!pago.monto">Es requirido</small>  
                            </div>
                            <div class="col-6" v-if="pago.tipo.moneda==1">
                                <label for="price">Monto Bs</label>
                                <InputNumber v-model="pago.montobs" mode="currency" currency="VEF" locale="en-US"/>
								<small class="p-invalid" v-if="!pago.montobs">Es requirido</small>  
                            </div>
                            <div class="col-8" v-if="pago.tipo.digital && pago.img==null">
                                <FileUpload chooseLabel="Subir JPG" uploadLabel="Cargar" mode="basic" :name="token" :url="url+'upload.php'"  accept=".jpg" :maxFileSize="1000000" @upload="myUploader(token+'.jpg')" :auto="true"/>
                            </div>
                            <div class="col-8" v-if="pago.tipo.digital && pago.img==null">
                                <FileUpload chooseLabel="Subir PDF" uploadLabel="Cargar" mode="basic" :name="token" :url="url+'upload.php'"  accept=".pdf" :maxFileSize="1000000" @upload="myUploader(token+'.pdf')" :auto="true"/>
                            </div>
                            <div class="col-12" v-if="pago.tipo.digital">
								<img style="width: 100px;" :src="url+'upload/'+pago.img" v-if="pago.img && pago.ext=='jpg'" @click="Ver(pago.img)"/>
								<img style="width: 70px;" src="images/pdf.png" v-if="pago.img && pago.ext=='pdf'" @click="Ver(pago.img)"/>
								<Button icon="pi pi-trash" class="button-rounded p-button-danger mr-2" @click="EliminarIMG(pago.img)" v-if="pago.img"/>
                            </div>
                        </div>
                    </div>                   
				</div>
			</div>
			<template #footer>
				<Button label="Agregar" icon="pi pi-check" class="p-button-secondary" @click="Agregar()" :loading="$store.state.loading" :disabled="$store.state.loading==true"/>
				<Button label="Cancelar" icon="pi pi-times" class="p-button-danger" @click="Dialog = false"/>
			</template>
		</Dialog>

    </div>
</template>

<script>
import API from "../service/API";
const Consulta = new API('Administracion','ImgPago');
import Funciones from "../service/Class";
const Aplic = new Funciones();

import Token from "uuid-token-generator";

export default {
	props: {
		tipopago: {
			type: Array,
			default: null
		},
		deuda: {
			type: Object,
			default: null
		},
		moneda: {
			type: Object,
			default: null
		},	
	},
    data() {
        return {
			Dialog: false,
            pago: {
                fecha: null,
				img: null,
            },
            size: '30vw',
            token: null,
			url: process.env.VUE_APP_API,
		}
	},
    created() {    
		this.$store.commit('LimpiarPagos');
        if (this.$store.state.mobile){this.size = '80vw';}
        this.pago.fecha = this.$store.state.fecha;
		this.Calcular();
        const tokgen = new Token();
        this.token = tokgen.generate();
    },
    methods: {
        Agregar() {
            if (this.pago.tipo == null) {
				this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Seleccione el tipo de pago', life: 5000});
			} else if (this.pago.fecha == null) {
                this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Indique la fecha del pago', life: 10000});
			} else if (this.pago.referencia == null && this.pago.tipo.digital) {
				this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca el número de referencia del pago', life: 5000});
			} else if ((!this.pago.monto && this.pago.tipo.moneda!=1) || (!this.pago.montobs && this.pago.tipo.moneda==1)) {
				this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca el monto del pago', life: 5000});
			} else {
				if(this.pago.tipo.moneda==1) {
					this.pago.monto = parseFloat(this.pago.montobs/this.$store.state.tasa);
				} else {
					this.pago.montobs = parseFloat(this.pago.monto*this.$store.state.tasa);
				}
				if(this.pago.referencia == null){this.pago.referencia = '-';}
				this.$store.state.pago.datos.push({
					id:			this.$store.state.pago.datos.length,
					fecha: 		this.pago.fecha,
					tipo: 		this.pago.tipo,
					monto: 		this.pago.monto,
					montobs: 	this.pago.montobs,
					tasa: 		this.$store.state.tasa,
					img: 		this.pago.img,
					ext: 		this.pago.ext,
					referencia:	this.pago.referencia,
				});
				const tokgen = new Token();
				this.token = tokgen.generate();
				this.pago = {fecha: this.$store.state.fecha};
				this.Calcular();
				this.Dialog = false;
			}
        },
		Eliminar(selec) {
			if(selec.img){
				Consulta.Procesar('Eliminar',{
					img: selec.img,
				}).then(response => {
					//this.$store.state.error =response;
					if(response.result){					
						this.$store.state.pago.datos = this.$store.state.pago.datos.filter(val => val !== selec);
					} else {
						this.$toast.add({
							severity:'warn', 
							summary: this.$t('Advertencia'), 
							detail: this.$t('Error'), 
							life: 3000
						});
					}
					this.Calcular();
					this.$store.commit('Loading');
				});			
			} else {
				this.$store.state.pago.datos = this.$store.state.pago.datos.filter(val => val !== selec);
				this.Calcular();
			}
		},
		Calcular(){
			let total = 0;
			let totalbs = 0;
			for(let sale of this.$store.state.pago.datos) {
				total += sale.monto;
				totalbs += sale.montobs;
			}
			this.$store.state.pago.monto = total;
			this.$store.state.pago.montobs = totalbs;
			this.$store.state.pago.deuda = this.deuda.final;
			this.$store.state.pago.deudabs = this.deuda.finalbs;
		},
		Ver(index){
			window.open(this.url+'upload/'+index, '_blank');
		},
		myUploader(img) {
			this.pago.ext = img.slice(-3);
			Consulta.Procesar('Agregar',{
				img: img,
			}).then(response => {
				//this.$store.state.error =response;
				if(response.result){					
                    this.pago.img = img;
				} else {
					this.$toast.add({
						severity:'warn', 
						summary: this.$t('Advertencia'), 
						detail: this.$t('Error en Foto'), 
						life: 3000
					});
				}
                const tokgen = new Token(256);
                this.token = tokgen.generate();
				this.$store.commit('Loading');
			});
		},
		EliminarIMG(img) {
			Consulta.Procesar('Eliminar',{
				img: img,
			}).then(response => {
				//this.$store.state.error =response;
				if(response.result){					
                    this.pago.img = null;
				} else {
					this.$toast.add({
						severity:'warn', 
						summary: this.$t('Advertencia'), 
						detail: this.$t('Error'), 
						life: 3000
					});
				}
				this.$store.commit('Loading');
			});			
		},
        onMenuToggle(event) {
            this.$emit('close-clave', event);
        },	
        Fecha(fecha){
			return Aplic.Fecha(fecha);
		},
        Moneda(value) {
			return Aplic.Moneda(value);
        },
	},
    watch: {
		display(newValue) {
			this.displayClave = newValue;
		}
	},


}
</script>